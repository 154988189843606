body {
  background-color: #fff;
  color: #fff;
  font-family: "Benton Sans", "Helvetica Neue", helvetica, arial, sans-serif;
  margin: 2em;
  white-space: normal;
  width: auto;
}

a {
  color: #fff;
}

noscript,span {
  color: #000;
}

ul {
  display: flex;
  list-style: none;
  flex-flow: wrap;
}

ul li {
  margin: 0 8px;
}

ul a {
  padding: .5em;
  text-decoration: none;
  font-size: 1.4em;
  border: 2px solid #fff;
  border-radius: .5em;
  white-space:nowrap;
  line-height: 2.5em;
}